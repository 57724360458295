import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import Home from './Components/Home';
import Team from './Components/Team';
import Article from './Components/Article';
import Header from './Components/Elements/Header';
import Footer from './Components/Elements/Footer';
import { useEffect } from 'react';
import  AOS  from 'aos';
import "aos/dist/aos.css"

function App() {

  useEffect(() => {
      AOS.init();
  },[])
  
  
  return (
    <>
      <Router>
        {/* <Header /> */}
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route  path='/team'>
            <Team />
          </Route>   
          <Route path='/Article' >
            <Article/>
          </Route>
          <Route >
            <Home />
          </Route>
       
        </Switch>
        {/* <Footer /> */}
      </Router>

    </>
  );
}

export default App;
