import React from 'react'

export default function Mission() {
  return (
    <div>
              <section className="bg_black2" id="mission">
                    <div className="container py-3 pt-lg-5">
                        <div className="row">
                            <div className="col-lg-6 text-lg-start text-center" data-aos="fade-left" data-aos-duration="3000">
                                <h1 className="text-white">Investment Philosophy</h1>
                                <p className="text-muted">
                                DAP is a network of investors and friends that have been in Crypto since 2013. We were early adopters of Bitcoin, smart contracts on Ethereum, alternative L1s and NFTs
                                </p>
                                <p className="text-muted">
                                We love getting our hands dirty to test out the latest tech. We actively participate in Crypto communities to see what people are working on.
                                </p>
                                <p className="text-muted">
                                Our mission is to find and develop new NFT use cases and to onboard the next wave of NFT adopters
                                </p>
                            </div>
                            <div className="col-lg-6" data-aos="fade-right" data-aos-duration="3000">
                                <img className="img-fluid" src="assets/img/Mission_statement.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
    </div>
  )
}
