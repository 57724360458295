import React from 'react'
import Footer from './Elements/Footer'
import Header from './Elements/Header'


function Team() {
  return (
    <div>
    <section className="bg_black2">
    <Header/>

      <div className="bg_black2">
        <div className="container">
          <div className="row align-items-center py-lg-5">
            <div className="col-lg-12 py-lg-0 py-4" data-aos="fade-left" data-aos-duration="3000">
              <div className="d-flex justify-content-lg-center justify-content-center text-lg-start text-center ">
                <div className="pe-lg-5">
                  <h1 className="text-white">Our Founder </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg_black2">
      <div className="container">
        <div className="row mx-lg-5" data-aos="fade-left" data-aos-duration="3000">
          <div className="col-lg-1 col-0"></div>
          <div className="col-lg-2 pe-5">
          <div className="d-flex justify-content-center ">
              <img src="assets/img/founder.jpeg" className='rounded-circle' alt="" />
            </div>
            <p className="fs-5 text-white text-center mb-0  pt-2 ">Founder</p>
             <h3 className='text-white text-center pt-3'> William Wong</h3>
          </div>
          <div className="col-lg-8">
     
            <div>
  

<p className='text-white text-lg-start text-center'>William, the founder of Digital Assets Partners is a crypto advisor and experienced investor 
Finding early investment opportunities in the Crypto space. Leveraging two decades of experience as a tech entrepreneur, he's now putting his focus on helping NFT projects become successful.</p>

<p className='text-white text-lg-start text-center'>
Wong graduated from the University of Waterloo with a Bachelor's Degree in Applied Science, Systems Design Engineering. Upon earning his degree, he worked in Silicon Valley for 10 years as a member of a startup and a startup founder. Wong was inspired to purchase his first Bitcoin after attending the first Bitcoin conference in Miami in 2013.
</p>
<p className='text-white text-lg-start text-center'>
  
Over the past several years, Wong gained more experience with cryptocurrency and has taken on the role of a thought leader in Tokenomics. He has since lectured at Draper University on Tokenomics while crafting token strategies for multiple projects. The first token strategy crafted by Wong was the Spray Token in 2017, an asset-backed token explicitly designed for the first crypto news network. In addition to creating Spray Token, he crafted Art Token in 2018 to create a currency to foster growth within the art community and to make transactions between artists and collectors a seamless experience.
</p>
<p className='text-white text-lg-start text-center'>
  
Working with Art Token in 2018 is where he began to see the potential for NFTs to transform the art world. As an avid collector of physical art, Wong felt an immediate connection to generative art and has been a collector of Art Blocks and fx(hash) collections since the inception of the platforms.

</p>
<p className='text-white text-lg-start text-center'>
William formed DAP in 2021 to bring together his experience and network to help new NFT builders succeed.
</p>
              {/* <p className="fs-6 text-muted text-center">Lorem Ipsum dolor</p> */}
            </div>
          </div>
          <div className="col-lg-1 col-0"></div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
  )
}

export default Team