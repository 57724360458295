import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
export default function
    () {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_mua6zaq', 'template_9s1qj0i', form.current, 'wbpeZSU403iIvE036')
            .then((result) => {
                console.log(result.text);
                console.log("ok");
                window.location.reload();
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div>
            <section className="bg_black2 pt-lg-5 pt-5" id="contact">
                <div className="container pt-lg-5">
                    <div className="row align-items-center">

                        <div className="col-lg-3 col-0"></div>
                        <div className="col-lg-6" data-aos="fade-right" data-aos-duration="3000">
                            {/* <form ref={form} onSubmit={sendEmail}>
                                <label>Name</label>
                                <input type="text" name="user_name" />
                                <label>Email</label>
                                <input type="email" name="user_email" />
                                <label>Message</label>
                                <textarea name="message" />
                                <input type="submit" value="Send" />
                            </form> */}
                            <h1 className='text-white text-center pb-4'>Contact</h1>
                            <div className="d-flex flex-sm-column ">
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="d-flex gap-3 flex-wrap justify-content-center ">
                                        <input type="text" style={{ backgroundColor: '#022021' }} className="border-0 py-2 px-3 text-muted input_width" name="from_name" placeholder="Name" required/>
                                        <input type="text" style={{ backgroundColor: '#022021' }} className="border-0 py-2 px-3 text-muted input_width" placeholder="Subject" name="Subject" required/>
                                    </div>
                                    <div className="d-flex gap-3 mt-3 flex-wrap justify-content-center">
                                        <input type="email" style={{ backgroundColor: '#022021' }} className="border-0 py-2 px-3 text-muted input_width" name="user_email" placeholder="Email" required/>
                                        <input type="text" style={{ backgroundColor: '#022021' }} className="border-0 py-2 px-3 text-muted input_width " placeholder="Phone Number" name="Phone_Number" required/>
                                    </div>
                                    <div className="mt-3 d-flex justify-content-center">
                                        <textarea className="border-0 px-3 text-muted text-area1" style={{ backgroundColor: '#022021', height: "120px" }} name="message" placeholder="Message" defaultValue={""} required/>
                                    </div>
                                    <div className="text-center">
                                        <input type="submit" value="Send" className="btn btn-warning border_radius text-white mt-3 text-area1" />
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-3 col-0"></div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}
