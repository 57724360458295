import { React, useState } from "react";
import { Nav, Navbar, Container } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import { AiFillLinkedin } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';




export default function Header(props) {
    function changeState() {
        props.change(Date.now())
    }
    const history = useHistory();
    const [first, setfirst] = useState(0)
    return (
        <>
            <Navbar className="navbar2  mx-auto  " bg="transparent" variant='transparent' expand="lg">
                <Container>
                    <Navbar.Brand className="ms-5" ><img className="header-logo" src={process.env.PUBLIC_URL + "assets/img/logo.png"} alt="" onClick={() => history.push('/')} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-between '>
                        <Nav className="col d-flex justify-content-between align-items-lg-center ">
                            <div className='d-flex col justify-content-center flex-lg-row flex-column algn-items-lg-center align-items-start gap-4 '>
                                <li className="nav-item">
                                    <Link to="home" className="nav-link text-white" aria-current="page">Home</Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link text-white " to="home#mission" tabIndex={-1} aria-disabled="true"><span onClick={() => { changeState() }}>
                                        Investing&nbsp;Philosophy </span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="team" className="nav-link text-white" >Founder</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="Article" className="nav-link text-white">Articles</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="home#contact" className="nav-link text-white" ><span onClick={() => { changeState() }}>Contact</span></Link>
                                </li>
                            </div>
                            <div className=' align-items-center d-flex me-5 gap-3'>
                                <a href="https://www.linkedin.com/in/william-wong-24240/"  > <AiFillLinkedin className='text-white fs-5' /></a>
                                <a href={"https://twitter.com/william02928661"}> <AiOutlineTwitter className='text-white fs-5' /></a>

                            </div>
                            {/* <button className="btn btn-warning border_radius text-white px-lg-4 GiHamburgerMenu">Lorem Ipsum</button> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    )
}
