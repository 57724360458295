import React from 'react'

export default function Footer() {
  return (
    <div>
      <footer className="py-1 mb-0">
        <p className="text-muted text-center mb-0"> copyright DAP 2022 </p>
      </footer>
    </div>
  )
}
