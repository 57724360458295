import React from 'react'
import Header from './Elements/Header'
import AOS from 'aos';
import { useEffect } from 'react';
import Footer from './Elements/Footer';

function Article() {
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, [])


  return (
    <div>
      <section className="bg_black2">
        <Header />
        <div className="bg_black2">
          <div className="container">
            <div className="row align-items-center py-lg-5">
              <div className="col-lg-12 py-lg-0 py-3 banner_a">
                <div className="d-flex justify-content-lg-center justify-content-center padding_top">
                  <div>
                    <h1 className="text-white text-center" data-aos="fade-left" data-aos-duration="3000">
                      Articles
                    </h1>
                    <p className="text-muted text-center px-2 px-lg-0" data-aos="fade-up">
                    Curated resources by the DAP team. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg_black2" >
      <div className="container">
        <h1 className="text-lg-center text-center text-white" data-aos="fade-right" data-aos-duration="3000">Latest Articles</h1>
        {/* <p className="text-lg-start text-center text-muted">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perferendis sed odio consectetur.
        </p> */}
        <div className="row py-3">
          <div className="col-lg-4 col-0" data-aos="fade-left" data-aos-duration="3000">
           
          </div>
          <div className="col-lg-4 col-12 pt-3 pt-lg-0 ms-lg-4 ms-0" data-aos="fade-up" data-aos-duration="3000">
            <div className="card border-0" style={{width: '18rem'}}>
              <img src="assets/img/article_card_top.png" className="card-img-top" alt="..." />
              <div className="card-body bg_green2">
                <p className="card-text text-muted">Lorem  ipsum</p>
                <h5 className="card-title text-white">Lorem Ipsum is a dummy
                  text of print</h5>
                <p className="card-text text-muted">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pt-3 pt-lg-0 col-0 " data-aos="fade-right" data-aos-duration="3000">
        
          </div>
        </div>
        {/* <div className="row pt-3 pt-lg-4">
          <div className="col-lg-4" data-aos="fade-left" data-aos-duration="3000">
            <div className="card border-0" style={{width: '18rem'}}>
              <img src="assets/img/article_card_top.png" className="card-img-top" alt="..." />
              <div className="card-body bg_green2">
                <p className="card-text text-muted">Lorem  ipsum</p>
                <h5 className="card-title text-white">Lorem Ipsum is a dummy
                  text of print</h5>
                <p className="card-text text-muted">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pt-3 pt-lg-0" data-aos="fade-up" data-aos-duration="3000">
            <div className="card border-0" style={{width: '18rem'}}>
              <img src="assets/img/article_card_top.png" className="card-img-top" alt="..." />
              <div className="card-body bg_green2">
                <p className="card-text text-muted">Lorem  ipsum</p>
                <h5 className="card-title text-white">Lorem Ipsum is a dummy
                  text of print</h5>
                <p className="card-text text-muted">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pt-3 pt-lg-0">
            <div className="card border-0" data-aos="fade-right" style={{width: '18rem'}}>
              <img src="assets/img/article_card_top.png" className="card-img-top" alt="..." />
              <div className="card-body bg_green2">
                <p className="card-text text-muted">Lorem  ipsum</p>
                <h5 className="card-title text-white">Lorem Ipsum is a dummy
                  text of print</h5>
                <p className="card-text text-muted">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
      <Footer />
    </div>
  )
}

export default Article