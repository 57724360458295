import React, { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
 import Header from './Elements/Header';
import Slider from "react-slick";
import Mission from './Elements/Mission';
import Contact from './Elements/Contact';
import Footer from './Elements/Footer';

export default function Home() {

    const settings = {
        slidesToShow: 4,
        rtl: false,
        slidesToScroll: 2,
        arrows: false,
        centerMode: false,
        autoplay: true,
        dots: false,
        autoplaySpeed: 3000,
        // centerMode: true;
        centerPadding: 0,
        responsive: [{
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1
            }
        }
        ]
    };
    const [first, setfirst] = useState(0)

    useEffect(() => {
        let path = window.location.hash
        if (path && path == "#mission") {
            document.querySelector("#mission").scrollIntoView();
        }
        if (path && path == "#contact") {
            document.querySelector("#contact").scrollIntoView();
        }
    }, [first])



    return (
        <>
            <div>
                <section className="bg_black2">
                    <Header change={setfirst} />

                    <div className="bg_black2">
                        <div className="container">
                            <div className="row align-items-center py-lg-5">
                                <div className="col-lg-6 py-lg-0 py-4">
                                    <div
                                        className="d-flex justify-content-lg-center justify-content-center text-lg-start text-center ">
                                        <div className="pe-lg-5">
                                            <h1 className="text-white" data-aos="fade-left" data-aos-duration="3000">Digital
                                                Assets Partners </h1>
                                            <p className="text-muted " data-aos="fade-right" data-aos-duration="3000">
                                                We are focused on investing in women-led projects in the NFT space
                                            </p>
                                            <div className="d-flex gap-3 justify-content-lg-start justify-content-center"
                                                data-aos="fade-up" data-aos-duration="3000">
                                                <div>
                                                    <a href='#contact' className="btn btn-warning border_radius text-white">
                                                        Contact
                                                    </a>
                                                </div>
                                                {/* <div>
                                            <button className="btn btn-outline-light border_radius ">
                                                Lorem Ipusum
                                            </button>
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 py-lg-0 py-3" data-aos="fade-right" data-aos-duration="3000">
                                    <div className="d-flex justify-content-lg-start justify-content-center">
                                        <img className="w-75" src="assets/img/download.png" alt="" />
                                    </div>
                                    <p className='text-muted text-center me-lg-5 pe-lg-5 pt-2'>
                                        fx(hash) - CMYK:RND #159
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>

                </section>
                <section className="bg_black2 pb-lg-3 pb-3">
                    <div className="container ">
                        <Slider {...settings}
                            className="slider autoplay autoplay2  wow animate__animated animate__fadeInLeft pt-lg-5 pt-5 mx-lg-5"
                            data-wow-duration="3s">
                            <div className="d-flex justify-content-center d-lg-block "><img className="product_img2"
                                src="assets/img/ava2.png" alt="" /></div>
                            <div className="d-flex justify-content-center d-lg-block "><img className="product_img2"
                                src="assets/img/ether.png" alt="" /></div>
                            <div className="d-flex justify-content-center d-lg-block "><img className="product_img3"
                                src="assets/img/fxhash.png" alt="" /></div>
                            <div className="d-flex justify-content-center d-lg-block "><img className="product_img"
                                src="assets/img/sqloho.png" alt="" /></div>
                            <div className="d-flex justify-content-center d-lg-block "><img className="product_img2"
                                src="assets/img/ava2.png" alt="" /></div>
                            <div className="d-flex justify-content-center d-lg-block "><img className="product_img2"
                                src="assets/img/ether.png" alt="" /></div>
                            <div className="d-flex justify-content-center d-lg-block "><img className="product_img3"
                                src="assets/img/fxhash.png" alt="" /></div>
                            <div className="d-flex justify-content-center d-lg-block "><img className="product_img"
                                src="assets/img/sqloho.png" alt="" /></div>
                        </Slider>
                    </div>
                </section>
                <Mission />
                <Contact />
                <Footer />



            </div>
        </>
    )
}